.fc-col-header-cell-cushion, .fc-col-header-cell-cushion:hover {
  color: #838383;
}

.fc-daygrid-event, .fc-daygrid-event:hover{
  background: rgba(255,255,255, 1);
  color: #A0A0A0;
  font-family: 'LatoBold' !important;
  cursor: pointer;
}

.fc-event-selected-custom{
  background: rgba(145,196,94, 1) !important;
  color: white !important;
  font-family: 'LatoBold' !important;
  cursor: pointer;
}

.fc-toolbar-title{
  color: #91c45e;
  font-weight: bold;
}

.fc-daygrid-day-number{
  color: #838383;
}

.fc-button{
  background-color: #91c45e !important;
}

.fc-day-today{
  background: #F2F2F2 !important;
}

.fc-event-time{
  color: transparent;
  width: 0px;
}

.fc-daygrid-event-dot{
  width: 0px;
  margin: 0px;
}

.fc-button{
  border: none !important;
  font-size: 1.2em !important;
  font-family: 'LatoBold' !important;
}

.fc-toolbar-title{
  color: #A0A0A0;
  text-transform: capitalize;
}

.fc-prev-button{
  margin-right: 3px !important;
}

.fc-media-screen{
  min-height: 550px;
}
